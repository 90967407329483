<script>
  export let company = "";
  export let claim = "";
  export let isUnit = false;
</script>

<div class="shield" class:shield--unit={isUnit}>
  <div class="shield__block shield--company">{company}</div>
  <div class="shield__block shield--claim">{claim}</div>
</div>


<style lang="scss">
  .shield {
    color: white;
    display: inline-block;
    font-weight: 400;
    font-style: italic;

    &:hover,
    &:focus {
      color: white;
      text-decoration: none;
    }

    &__block {
      padding: 5px 0;
      width: 150px;
    }

    &--company {
      background: var(--primary);
      height: 66px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      font-size: 1.3rem;
      padding: 0 15px;
      font-weight: 300;
    }

    &--claim {
      background: var(--secondary);
      text-align: right;
      height: 34px;
      padding-right: 15px;
    }

    &--unit {
      position: absolute;
      bottom: 30px;
      right: 30px;

      .shield--company {
        height: 10px;
      }

      @media (min-width:  1199.98px) and (max-width: 1350px) {
        bottom: 15px;
        right: 15px;
      }
    }
  }
</style>
